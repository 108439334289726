import { render, staticRenderFns } from "./one.vue?vue&type=template&id=ac21ece6&scoped=true&"
var script = {}
import style0 from "./one.vue?vue&type=style&index=0&id=ac21ece6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac21ece6",
  null
  
)

export default component.exports