<template>
  <div class="diffinfo">
    <div class="diffinfocenter">
      <header>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/diffusion' }">成果推广</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/diffusion' ,query:{text:showtitle} }">{{showtitle}}</el-breadcrumb-item>
          <el-breadcrumb-item>{{showtext}}</el-breadcrumb-item>
        </el-breadcrumb>
      </header>
      <main>
        <!-- 国家 -->
        <gone v-if="showtitle=='国家教育教学成果'&&showid==1"></gone>
        <gtwo v-if="showtitle=='国家教育教学成果'&&showid==2"></gtwo>
        <gthree v-if="showtitle=='国家教育教学成果'&&showid==3"></gthree>
        <gfour v-if="showtitle=='国家教育教学成果'&&showid==4"></gfour>
        <gfive v-if="showtitle=='国家教育教学成果'&&showid==5"></gfive>
        <!-- 市级 -->
        <sone v-if="showtitle=='市级教育教学成果'&&showid==1"></sone>
        <stwo v-if="showtitle=='市级教育教学成果'&&showid==2"></stwo>
        <sthree v-if="showtitle=='市级教育教学成果'&&showid==3"></sthree>
        <sfour v-if="showtitle=='市级教育教学成果'&&showid==4"></sfour>
        <sfive v-if="showtitle=='市级教育教学成果'&&showid==5"></sfive>
        <!-- 区级 -->
        <quone v-if="showtitle=='区级教育教学成果'&&showid==1"></quone>
        <qutwo v-if="showtitle=='区级教育教学成果'&&showid==2"></qutwo>
        <quthree v-if="showtitle=='区级教育教学成果'&&showid==3"></quthree>
        <qufour v-if="showtitle=='区级教育教学成果'&&showid==4"></qufour>
        <qufive v-if="showtitle=='区级教育教学成果'&&showid==5"></qufive>
        <!-- 其他 -->
        <qione v-if="showtitle=='其他教育教学成果'&&showid==1"></qione>
        <qitwo v-if="showtitle=='其他教育教学成果'&&showid==2"></qitwo>
        <qithree v-if="showtitle=='其他教育教学成果'&&showid==3"></qithree>
        <qifour v-if="showtitle=='其他教育教学成果'&&showid==4"></qifour>
        <qifive v-if="showtitle=='其他教育教学成果'&&showid==5"> </qifive>
      </main>
    </div>
  </div>
</template>
<script>
import gone from "./guojia/one.vue";
import gtwo from "./guojia/two.vue";
import gthree from "./guojia/three.vue";
import gfour from "./guojia/four.vue";
import gfive from "./guojia/five.vue";

import quone from "./quji/one.vue";
import qutwo from "./quji/two.vue";
import quthree from "./quji/three.vue";
import qufour from "./quji/four.vue";
import qufive from "./quji/five.vue";

import sone from "./shiji/one.vue";
import stwo from "./shiji/two.vue";
import sthree from "./shiji/three.vue";
import sfour from "./shiji/four.vue";
import sfive from "./shiji/five.vue";

import qione from "./qita/one.vue";
import qitwo from "./qita/two.vue";
import qithree from "./qita/three.vue";
import qifour from "./qita/four.vue";
import qifive from "./qita/five.vue";

export default {
  components: {
    gone,
    gtwo,
    gthree,
    gfour,
    gfive,

    quone,
    qutwo,
    quthree,
    qufour,
    qufive,

    sone,
    stwo,
    sthree,
    sfour,
    sfive,

    qione,
    qitwo,
    qithree,
    qifour,
    qifive,
  },
  data() {
    return {
      showid: null,
      showtitle: null,
      showtext: null,
    };
  },
  created() {
    console.log(this.$route.query, "路由");
    this.showid = this.$route.query.id;
    this.showtitle = this.$route.query.title;
    this.showtext = this.$route.query.text;
  },
};
</script>
<style lang="scss" scoped>
.diffinfo {
  .diffinfocenter {
    width: 1200px;
    margin: 0 auto;
    header {
      padding: 15px 0;
    }
  }
}
</style>